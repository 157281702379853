interface ReclaimitIframeProps {
    height: string | number;
    src: string;
    width: string | number;
}

const ReclaimitIframe = ({ src = '', height = '800px', width = '615px' }: ReclaimitIframeProps) => (
    <iframe id="reclaimitiframe" src={src} height={height} width={width} />
);

export default ReclaimitIframe;
