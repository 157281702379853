import PropTypes from 'prop-types';
import ReclaimIt from 'components/content-components/ReclaimIt';

const ReclaimItDataTransformerBlock = ({ content = {} }) => {
    const { iframeSrc } = content;

    return <ReclaimIt src={iframeSrc} />;
};

ReclaimItDataTransformerBlock.propTypes = {
    content: PropTypes.object.isRequired,
};

export default ReclaimItDataTransformerBlock;
