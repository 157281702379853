import ReclaimItDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ReclaimItDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const ReclaimItModule = ({ ...rest }) => {
    const [css] = useStyletron();

    return (
        <section className={css({ marginBottom: 'var(--margin-bottom-module)' })}>
            <ReclaimItDataTransformerBlock {...rest} />
        </section>
    );
};

export default ReclaimItModule;
